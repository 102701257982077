type FontWeight = 'REGULAR' | 'SEMIBOLD' | 'BOLD' | 'EXTRABOLD';
const FontWeightMap = {
  REGULAR: 400,
  SEMIBOLD: 600,
  BOLD: 700,
  EXTRABOLD: 800
};

export const theme = {
  color: {
    white: '#FFFFFF',
    black: '#000000',
    blue: {
      100: '#160E35',
      200: '#2E225B',
      300: '#362B70',
      400: '#166194',
      600: '#A4A7FF',
      700: '#00008B'
    },
    gray: {
      100: '#121623',
      150: '#7c7698',
      200: '#A19BBA',
      300: '#F3F6FE'
    }
  },
  breakpoints: {
    desktop: 1920,
    preDesktop: 1720,
    mediumDesktop: 1440,
    smallDesktop: 1366,
    tabletLandscape: 1024,
    tabletPortrait: 768,
    largePhone: 600,
    phone: 480
  },
  spacing: {
    huge: '40px',
    bigger: '30px',
    big: '20px',
    mid: '10px',
    small: '5px'
  },
  size: {
    menuHeight: '60px',
    subMenuHeight: '34px'
  },
  radius: {
    main: '6px'
  },
  onScreen: (screen: string) => {
    switch (screen) {
      case 'DESKTOP':
        return `@media (min-width: ${theme.breakpoints.desktop}px)`;
      case 'PRE_DESKTOP':
        return `@media (min-width: ${theme.breakpoints.preDesktop}px)`;
      case 'MEDIUM_DESKTOP':
        return `@media (min-width: ${theme.breakpoints.mediumDesktop}px)`;
      case 'SMALL_DESKTOP':
        return `@media (min-width: ${theme.breakpoints.smallDesktop}px)`;
      case 'TABLET_LANDSCAPE':
        return `@media (min-width: ${theme.breakpoints.tabletLandscape}px)`;
      case 'TABLET':
        return `@media (min-width: ${theme.breakpoints.tabletPortrait}px)`;
      case 'LARGE_PHONE':
        return `@media (min-width: ${theme.breakpoints.largePhone}px)`;
      case 'PHONE':
        return `@media (min-width: ${theme.breakpoints.phone}px)`;
      default:
        return `@media (min-width: ${theme.breakpoints.desktop}px)`;
    }
  },
  shadow: {
    depth0: '0',
    depth1: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)',
    depth2: '0 3px 6px rgba(0, 0, 0, 0.09), 0 3px 6px rgba(0, 0, 0, 0.09)',
    depth3: '0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.09)',
    depth4: '0 14px 28px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.08)',
    depth5: '0 19px 38px rgba(0, 0, 0, 0.08), 0 15px 12px rgba(0, 0, 0, 0.08)',
    depth6: '0px 0px 28px 8px rgba(240, 240, 240), 0px 0px 10px 0px rgba(240, 240, 240)',
    default: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)'
  },
  font: (size: number, weight: FontWeight = 'REGULAR') => `
    font-size: ${size / 16}rem;
    font-weight: ${FontWeightMap[weight]};
  `
};
