import React from 'react';
import { theme } from './src/lib/theme';
import { ThemeProvider } from 'styled-components';
import scrollToElement from 'scroll-to-element';

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const { href } = routerProps.location;

  const skipScrollForArguments = ['?activeTab', '?page', '?activeSlide'];

  if (skipScrollForArguments.some((item) => href.includes(item))) {
    const isInitial = prevRouterProps?.location === undefined;

    if (isInitial) {
      return false;
    }

    if (prevRouterProps.location.pathname === routerProps.location.pathname) {
      return false;
    } else {
      return true;
    }
  }

  return true;
};
